import React, {
  FunctionComponent as FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getMessageAcceptObject } from '../util/storage-utils';
import {
  renderPageComponents,
  getStatusAlertComponent,
  getButtonVariable,
} from '../util/contentful-render-utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import { ShortcutsBar } from '../components/contentful-blocks/ShortcutsBar';
import { isIE } from 'react-device-detect';
import SupportedBrowserMessage from '../components/supported-browser-message';
import { local } from '../lib/storage';
import { LanguageContext } from '../providers/LanguageProvider';
import version from './version.json';
import { HomePageHeader } from '../components/contentful-blocks/HomePageHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(25),
      '& .ContentfulElementColumns2 [class^="makeStyles-root"], & .ContentfulElementColumns3 [class^="makeStyles-root"], & .ContentfulModuleAccordion [class^="makeStyles-root"], & article.ProgramCard, & .ContentfulModuleDocumentList, & .StaticList, & .ContentfulModuleToc [class^="makeStyles-root"], & .ContentfulElementCallout [class^="makeStyles-root"], & .ContentfulModuleRelated [class^="makeStyles-root"], & .ContentfulModuleContentList, & figure.uiTableExtention, & .spacingComponent.ContentfulElementMessagingBlock, & .spacingComponent.ContentfulElementPullquote, & .spacingComponent.ContentfulElementCta, & .CtaCard, & .GallerySlider, & .spacingComponent.ContentfulModulePromoGroup, & .spacingComponent.ContentfulWrapperembedMedia, & article.WrapperImage, & .spacingComponent.ContentfulHeaderhero, & .spacingComponent.ContentfulHeaderSplit, & aside.SocialFeed, & .ContentfulContentStatusAlert': {
        maxWidth: theme.typography.pxToRem(1280),
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  }),
);

const Home: FC = () => {
  const { page } = useStaticQuery(graphql`
    query homepageContentMVP2 {
      page: allContentfulPageHome(filter: { slug: { eq: "mvp2-home-page" } }) {
        nodes {
          node_locale
          contentful_id
          entryName
          shortMenuTitle
          slug
          pageTitle
          browserTitle
          header {
            __typename
            entryName
            heading
            subheading
            mobileImage {
              file {
                contentType
                fileName
                url
              }
            }
            image {
              file {
                contentType
                fileName
                url
              }
            }
            imagePosition
            button {
              buttonText
              buttonType
              buttonIcon {
                file {
                  contentType
                  fileName
                  url
                }
              }
            }
            buttonLink {
              ...pageArticleLink
              ...pageBasicLink
              ...pageHomeLink
              ...pageListLink
              ...pageNewsLandingLink
              ...pageNewsReleaseLink
              ...ModuleOverlay
              ...pagePromotionalLink
              ...wrapperExternalLink
            }
          }
          contentEntries {
            ...ModuleContentBody
            ...ModuleNote
            ...ModuleStatusAlert
            ...ModuleCallOut
            ...ModuleColumn2
            ...ModuleColumn3
            ...ModuleContact
            ...ModuleCallToAction
            ...ModuleMessagingBlock
            ...ModulePullQuote
            ...ModuleSplitHeader
            ...ModuleHeroHeader
            ...ModuleAccordion
            ...ModuleContentList
            ...ModuleDocumentList
            ...GallerySlider
            ...ModulePromoGroup
            ...ModuleRelated
            ...ModuleTOC
            ...ModuleFormWrapper
            ...ModuleImageWrapper
            ...ModuleEmbedMediaWrapper
            ...ProgramCard
            ...StaticList
            ...CardLayout
            ...CTACard
            ...SocialFeed
            ...ModuleTable
          }
          metaDescriptionSeo
          excludeFromGoogleSearch
          excludeFromXmlSiteMap
          statusAlert {
            ...ModuleStatusAlert
          }
        }
      }
    }
  `);
  // grab the current locale.
  const { language } = useContext(LanguageContext);
  const [content] = page.nodes.filter(
    (pg: any) => pg.node_locale === (language || 'en'),
  );

  const classes = useStyles();

  const header = () => {
    const [hero] = [content?.header].filter(
      (entry: any) => entry?.__typename === 'ContentfulHeaderhero',
    );
    const btnProps = getButtonVariable(hero?.button, hero?.buttonLink);
    return (
      <>
        {hero && (
          <HomePageHeader
            image={hero?.image?.file?.url}
            heading={hero?.heading}
            copy={hero?.subheading}
            button={btnProps}
            mobileImage={hero?.mobileImage?.file?.url}
          />
        )}
      </>
    );
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const messageAcceptTime = local.getItem(getMessageAcceptObject.key);

  useEffect(() => {
    if (messageAcceptTime === null) {
      setIsDialogOpen(true);
    } else {
      if (
        messageAcceptTime &&
        new Date().getTime() - Number(messageAcceptTime) >
          getMessageAcceptObject.hours * 60 * 60 * 1000
      ) {
        local.removeItem(getMessageAcceptObject.key);
        setIsDialogOpen(true);
      }
    }
  }, []);

  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>{content?.browserTitle || content?.pageTitle}</title>
        {content?.metaDescriptionSeo && (
          <meta name="description" content={content.metaDescriptionSeo} />
        )}
        <meta name="source" content={version.source} />
        <meta name="version" content={version.version} />
        <meta name="build-time" content={version['build-time']} />
      </Helmet>
      {header()}
      <div className={classes.root}>
        <ShortcutsBar />
        {getStatusAlertComponent(content?.statusAlert)}
        {renderPageComponents({
          entriesData: content?.contentEntries || null,
        })}
      </div>
      {isIE && isDialogOpen && (
        <SupportedBrowserMessage
          messageAcceptKey={getMessageAcceptObject.key}
        />
      )}
    </>
  );
};

export default Home;
