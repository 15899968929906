import React, { FunctionComponent } from 'react';
import Container from '@material-ui/core/Container';
import PgeButton, { IPGEButtonProps } from '../../pge-button/PGEButtons';
import { Field } from '../../../contentful.d';
// eslint-disable-next-line import/no-unassigned-import
import '../styles/hero.css';

import TEST_IDS from '../../../constants/test_ids';
import { useTranslation } from '../../../hooks/useTranslation';
import { Card, Grid, Typography, makeStyles } from '@material-ui/core';
import SignIn from '../../sign-in-form/SignIn';
import { useIsMobile } from '../../../util/style-utils';
import useAuth from '../../../hooks/useAuth';
import colors from '../../../themes/main-colors';

interface HomePageHeaderProps {
  heading: string;
  image?: string;
  copy?: string;
  button?: IPGEButtonProps | null;
  readonly imagePosition?: Field['Position'];
  mobileImage?: string;
}

const useStyles = makeStyles(theme => ({
  rootContainer: {
    '& .MuiInputBase-root, .MuiFormLabel-root': {
      fontSize: '1.125rem',
    },
    '& .hero-content': {
      '& h1,p': {
        color: colors.white,
      },
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  signIn: {
    margin: '0px 50px',
    textShadow: 'none',
  },
  card: {
    height: '100%',
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 2em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
export const HomePageHeader: FunctionComponent<HomePageHeaderProps> = ({
  image,
  heading,
  copy,
  button,
  imagePosition,
  mobileImage,
}) => {
  const isMobile = useIsMobile();

  const backgroundPictureUrl =
    isMobile && mobileImage
      ? `url(${mobileImage})`
      : image
      ? `url(${image})`
      : 'none';
  const heroImageCSS =
    // HCL asked to keep this code to engage it post MVP.
    'linear-gradient(90deg, #071A2BE6, #122B417F, #1C304A00) ,' +
    backgroundPictureUrl;
  const { t } = useTranslation();
  const classes = useStyles();

  const { isAuthenticated } = useAuth();
  return (
    <>
      <div
        className={`position-${imagePosition} hero-container ${classes.rootContainer}`}
        data-testid={TEST_IDS.LANDING_HERO_HEADER}
        style={{
          padding: '50px 0',
          backgroundImage: heroImageCSS,
          ...(!isAuthenticated && { height: 'unset' }),
        }}
      >
        {!isAuthenticated && !isMobile ? (
          <Container maxWidth="lg">
            <Grid
              container
              className={classes.gridContainer}
              spacing={4}
              alignItems="stretch"
              justify="center"
            >
              <Grid item xs={12} md={5} className={classes.signIn}>
                <Card className={classes.card}>
                  <Typography variant={'h1'}>
                    {t('CUSTOMER_SIGN_IN')}
                  </Typography>
                  <SignIn isEmbedded={true} />
                </Card>
              </Grid>
              <Grid item xs={12} md={7} className="hero-content">
                {heading?.trim() && <h1>{heading}</h1>}
                {copy?.trim() && <p>{copy}</p>}
                {button && <PgeButton {...button} />}
              </Grid>
            </Grid>
          </Container>
        ) : (
          <Container className="hero-content">
            {heading?.trim() && <h1>{heading}</h1>}
            {copy?.trim() && <p>{copy}</p>}
            {button && <PgeButton {...button} />}
          </Container>
        )}
      </div>
    </>
  );
};
